import {
  SET_ORDENES,
  UPDATE_ORDEN_IN_ARRAY,
  ADD_ORDEN_IN_ARRAY,
} from "./ordenesTypes";

export const ordenesReducers = (state = [], action) => {
  switch (action.type) {
    case SET_ORDENES:
      return action.payload;
    case ADD_ORDEN_IN_ARRAY:
      return [action.payload, ...state];

    case UPDATE_ORDEN_IN_ARRAY:
      const index = state.findIndex((orden) => orden.id === action.payload.id);
      const newArray = [...state];
      newArray[index] = action.payload;
      return newArray;
    default:
      return state;
  }
};
