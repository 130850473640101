import { SET_LAB } from "./labTypes";

const initialState = {
  id: "",
  empresaId: "",
  razonSocial: "",
  ruc: "",
  telefono: "",
};

export const labReducers = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAB:
      return action.payload;
    default:
      return state;
  }
};
