import { SET_ORDEN, NEW_ORDEN } from "./ordenTypes";
import { COrden } from "../../Entidades/Orden";

export const ordenReducers = (state = new COrden(), action) => {
  switch (action.type) {
    case SET_ORDEN:
      return action.payload;
    case NEW_ORDEN:
      return new COrden();
    default:
      return state;
  }
};
