import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyDrbac4fduwgdd6cd3QMbyBbxXFLpJm_1o",
  authDomain: "odontoplus-4db47.firebaseapp.com",
  databaseURL: "https://odontoplus-4db47.firebaseio.com",
  projectId: "odontoplus-4db47",
  storageBucket: "odontoplus-4db47.appspot.com",
  messagingSenderId: "1065367709597",
  appId: "1:1065367709597:web:ee8db3fcb39a281cb9f174",
  measurementId: "G-DH9YT5J6W8",
};

const app = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const firestorage = firebase.storage();
const auth = firebase.auth();
export { auth, db, firestorage };
