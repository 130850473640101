import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import {
  TextArea,
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  TablaHeaderTextoButtons,
  Tabla,
  LinkCancelar,
  ButtonPagar,
  TablaButtons,
  ContainerBody,
  FlowContainer,
  AddButton,
  Select,
  ItemContainer,
  ButtonInsideContainer,
  InputButton,
  ButtonInside,
} from "./styles";

export const EditaOrdenLab = ({
  orden,
  onChangeOrden,

  onSave,
  onCloseModal,
  refOrden,
  onChangeItem,
  onAddEstado,
  onRemoveEstado,
}) => {
  return (
    <form ref={refOrden}>
      <ContainerTitle>
        <ContainerTitleText>{`Editar Orden: ${orden.numCor}`}</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <Tabla>
          <TablaBody>
            <tr>
              <TablaHeaderTextoItems>Laboratorio</TablaHeaderTextoItems>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Envío: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="envio">
                <input
                  type="date"
                  value={orden.fecEnv}
                  name="fecEnv"
                  onChange={onChangeOrden}
                  required
                  disabled
                ></input>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Paciente: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues>
                <input
                  type="text"
                  value={orden.pacienteNombres}
                  name="pacienteNombres"
                  onChange={onChangeOrden}
                  required
                  disabled
                ></input>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Recep: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="recep">
                <input
                  type="date"
                  value={orden.fecRec}
                  name="fecRec"
                  onChange={onChangeOrden}
                  required
                  disabled
                ></input>
              </TablaHeaderTextoValues>
            </tr>
          </TablaBody>
        </Tabla>

        {orden.items.map((item, index) => (
          <ItemContainer>
            <div>{` ${item.nombre} dientes:[${item.dientes}] S/ ${item.precio}`}</div>
            <div>
              <div>
                <textarea
                  name="nota"
                  rows="3"
                  cols="50"
                  placeholder="Descripcion de la Nota"
                  value={item.nota}
                  disabled
                ></textarea>
              </div>
            </div>
            <div>
              <label>terminado</label>
              <input
                name="terminado"
                type="checkbox"
                checked={item.terminado}
                disabled
              ></input>
            </div>
            <div>
              <ButtonInsideContainer>
                <InputButton
                  name="nuevoEstado"
                  placeholder="Nuevo estado"
                  value={item.nuevoEstado}
                  onChange={(e) => onChangeItem(e, index)}
                ></InputButton>
                <ButtonInside onClick={(e) => onAddEstado(e, index)}>
                  Guardar
                </ButtonInside>
              </ButtonInsideContainer>
              {item.estados && (
                <ul>
                  {item.estados.map((estado, indey) => (
                    <li>
                      {estado}
                      <IconButton
                        aria-label=""
                        onClick={() => onRemoveEstado(index, indey)}
                        isShown={true}
                      >
                        <Tooltip
                          TransitionComponent={Zoom}
                          placement="top"
                          title="Eliminar"
                        >
                          <DeleteIcon style={{ color: "red" }} />
                        </Tooltip>
                      </IconButton>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </ItemContainer>
        ))}
      </ContainerBody>
      <div>
        <LinkCancelar onClick={onCloseModal}>Cancelar</LinkCancelar>
        <ButtonPagar onClick={onSave}>Guardar</ButtonPagar>
      </div>
    </form>
  );
};
