import React, { useState, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import { auth } from "../../services/firebase/Config";
import firebase from "firebase/app";
import {
  setUsuarioSesion,
  httpRequest,
  httpSuccess,
  httpFailure,
} from "../../redux/sesion/sesionActions";

import { guardaLabUsuario, setLab } from "../../redux/lab/labActions";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import NuevoLogo from "../../img/slider/nuevoLogo.png";
import EjemploSlider from "../../img/slider/ejemploSlider.png";
import EjemploSlider2 from "../../img/slider/ejemploSlider2.png";
import { fetchUsuario } from "../../services/firebase/usuarios";
import { useUser } from "reactfire";
import { useToasts } from "react-toast-notifications";
import { useForm } from "react-hook-form";
import {
  LoginViewWrapper,
  FooterWrapper,
  LoginView,
  SectionForm,
  ImgLogo,
} from "./styles";
import { AuthContext } from "../../components/Auth.js";
import Carousel from "react-bootstrap/Carousel";

let regionesPeru = [
  {
    CODIGO: "01",
    DEPARTAMENTO: "AMAZONAS",
  },
  {
    CODIGO: "02",
    DEPARTAMENTO: "ANCASH",
  },
  {
    CODIGO: "03",
    DEPARTAMENTO: "APURIMAC",
  },
  {
    CODIGO: "04",
    DEPARTAMENTO: "AREQUIPA",
  },
  {
    CODIGO: "05",
    DEPARTAMENTO: "AYACUCHO",
  },
  {
    CODIGO: "06",
    DEPARTAMENTO: "CAJAMARCA",
  },
  {
    CODIGO: "07",
    DEPARTAMENTO: "CALLAO",
  },
  {
    CODIGO: "08",
    DEPARTAMENTO: "CUSCO",
  },
  {
    CODIGO: "09",
    DEPARTAMENTO: "HUANCAVELICA",
  },
  {
    CODIGO: 10,
    DEPARTAMENTO: "HUANUCO",
  },
  {
    CODIGO: 11,
    DEPARTAMENTO: "ICA",
  },
  {
    CODIGO: 12,
    DEPARTAMENTO: "JUNIN",
  },
  {
    CODIGO: 13,
    DEPARTAMENTO: "LA LIBERTAD",
  },
  {
    CODIGO: 14,
    DEPARTAMENTO: "LAMBAYEQUE",
  },
  {
    CODIGO: 15,
    DEPARTAMENTO: "LIMA",
  },
  {
    CODIGO: 16,
    DEPARTAMENTO: "LORETO",
  },
  {
    CODIGO: 17,
    DEPARTAMENTO: "MADRE DE DIOS",
  },
  {
    CODIGO: 18,
    DEPARTAMENTO: "MOQUEGUA",
  },
  {
    CODIGO: 19,
    DEPARTAMENTO: "PASCO",
  },
  {
    CODIGO: 20,
    DEPARTAMENTO: "PIURA",
  },
  {
    CODIGO: 21,
    DEPARTAMENTO: "PUNO",
  },
  {
    CODIGO: 22,
    DEPARTAMENTO: "SAN MARTIN",
  },
  {
    CODIGO: 23,
    DEPARTAMENTO: "TACNA",
  },
  {
    CODIGO: 24,
    DEPARTAMENTO: "TUMBES",
  },
  {
    CODIGO: 25,
    DEPARTAMENTO: "UCAYALI",
  },
];

export const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [usuario, setUsuario] = useState({ nombres: "", email: "", uid: "" });

  const [step, setStep] = useState(0);
  const verificaUsuario = async (login) => {
    let usuario = await fetchUsuario(login.user.email);
    if (!usuario.id) {
      setUsuario({
        nombres: login.user.displayName,
        email: login.user.email,
        uid: login.user.uid,
      });
      setStep(1);
      //dispatch(setUsuarioSesion(usuario));
      //history.push("/paciente");
    } else
      addToast("Tu cuenta ya existe, ingresa desde Login", {
        appearance: "error",
      });
  };

  const handleLoginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth
      .signInWithPopup(provider)
      .then((login) => {
        verificaUsuario(login);
        //dispatch(setUsuarioSesion(login.user.email));
        //history.push("/paciente");
      })
      .catch((err) => auth.signOut());
  };

  const onSubmit = () => {
    handleLoginWithGoogle();
  };

  const onSubmitLastStep = (e, empresa) => {
    e.preventDefault();
    dispatch(setLab(empresa));
    try {
      dispatch(guardaLabUsuario(empresa, usuario));
      history.push("/");
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const PasoCero = () => {
    return (
      <div className="form__container">
        <div className="img__container">
          <ImgLogo src={NuevoLogo} alt="Sysdent logo" />
          <p>Crea tu cuenta gratis</p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <button className="form__button" type="submit">
            Crear con Google
          </button>
          Si ya tienes una cuenta ingresa con tus crendenciales
          <div className="form__forgotpassword__container">
            <Link to="/login" className="changeLink">
              INGRESAR
            </Link>
          </div>
        </form>
      </div>
    );
  };

  const PasoUno = () => {
    return (
      <div className="form__container">
        <div className="img__container">
          <ImgLogo src={NuevoLogo} alt="Sysdent logo" />
          <p>Crea tu cuenta gratis</p>
        </div>

        <form>
          <button className="form__button" onClick={() => setStep(0)}>
            Atras
          </button>
          <fieldset>
            <input
              className="form__input"
              placeholder="Nombres y Apellidos"
              name="nombres"
              value={usuario.nombres}
              readOnly
            />
          </fieldset>
          <fieldset>
            <input
              className="form__input"
              placeholder="Correo"
              name="email"
              value={usuario.email}
              readOnly
            />
          </fieldset>

          <button className="form__button" onClick={() => setStep(2)}>
            Siguiente
          </button>
        </form>
      </div>
    );
  };

  const PasoDos = () => {
    const [empresa, setEmpresa] = useState({
      razonSocial: "",
      ruc: "",
      telefono: "",
      ubicacion: "",
    });

    const handleChangeEmpresa = (e) => {
      setEmpresa({ ...empresa, [e.target.name]: e.target.value });
    };
    return (
      <div className="form__container">
        <div className="img__container">
          <ImgLogo src={NuevoLogo} alt="Sysdent logo" />
          <p>Datos de la empresa</p>
        </div>

        <form onSubmit={(e) => onSubmitLastStep(e, empresa)}>
          <button className="form__button" onClick={() => setStep(1)}>
            Atras
          </button>
          <fieldset>
            <input
              //ref={register({ required: true })}
              className="form__input"
              placeholder="Razon Social"
              name="razonSocial"
              value={empresa.razonSocial}
              onChange={handleChangeEmpresa}
              required
            />
          </fieldset>
          <fieldset>
            <input
              //ref={register({ required: true })}
              className="form__input"
              placeholder="RUC"
              name="ruc"
              value={empresa.ruc}
              onChange={handleChangeEmpresa}
              required
            />
          </fieldset>
          <fieldset>
            <input
              //ref={register({ required: true })}
              className="form__input"
              placeholder="Telefono"
              name="telefono"
              value={empresa.telefono}
              onChange={handleChangeEmpresa}
              required
            />
          </fieldset>
          <fieldset>
            <select
              className="form_input"
              //ref={register({ required: true })}
              name="ubicacion"
              value={empresa.ubicacion}
              onChange={handleChangeEmpresa}
              required
            >
              <option selected hidden value="">
                UBICACION...
              </option>
              {regionesPeru.map((item) => (
                <option value={item.CODIGO}>{item.DEPARTAMENTO}</option>
              ))}
            </select>
          </fieldset>

          <button className="form__button" type="submit">
            Terminar
          </button>
        </form>
      </div>
    );
  };

  const StepForm = () => {
    switch (step) {
      case 0:
        return <PasoCero />;
      case 1:
        return <PasoUno />;
      case 2:
        return <PasoDos />;

      default:
        return null;
    }
  };

  if (isLoading) return <CircularProgress color="secondary" />;
  return (
    <LoginViewWrapper>
      <LoginView>
        <SectionForm>
          <StepForm />
        </SectionForm>
        <FooterWrapper>
          <Carousel variant="dark" class="carouselHeigth">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={EjemploSlider}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={EjemploSlider2}
                alt="Second slide"
              />
            </Carousel.Item>
          </Carousel>
        </FooterWrapper>
      </LoginView>
    </LoginViewWrapper>
  );
};
