import { combineReducers } from "redux";
import { sesionReducers } from "./sesion/sesionReducers";
import { ordenReducers } from "./orden/ordenReducers";
import { ordenesReducers } from "./ordenes/ordenesReducers";
import { labReducers } from "./lab/labReducers";

const allReducers = combineReducers({
  sesion: sesionReducers,
  orden: ordenReducers,
  lab: labReducers,
  ordenes: ordenesReducers,
});

export default allReducers;
