import React from "react";

import {
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  Tabla,
  LinkCancelar,
  ContainerBody,
  Select,
  ButtonPagar,
  ItemContainer,
  ButtonInsideContainer,
  InputButton,
  ButtonInside,
} from "./styles";

export const AceptaOrdenEnLab = ({
  orden,
  onCloseModal,
  refOrden,
  onClick,
  onChangeOrden,
  title,
  onChangeItem,
}) => {
  return (
    <form ref={refOrden}>
      <ContainerTitle>
        <ContainerTitleText>{title}</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <Tabla>
          <TablaBody>
            <tr>
              <TablaHeaderTextoItems>Laboratorio</TablaHeaderTextoItems>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Envío: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="envio">
                <input
                  type="date"
                  value={orden.fecEnv}
                  name="fecEnv"
                  required
                  disabled
                ></input>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fecha Prueba: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="recep">
                <input
                  type="date"
                  value={orden.fecRec}
                  name="fecRec"
                  onChange={onChangeOrden}
                  disabled
                  required
                ></input>
              </TablaHeaderTextoValues>
            </tr>
          </TablaBody>
        </Tabla>

        {orden.items.map((item, index) => (
          <ItemContainer>
            <div>{` ${item.nombre} dientes:[${item.dientes}] S/ ${item.precio}`}</div>
            <div>
              <div>
                <input
                  type="text"
                  name="precioFinal"
                  value={item.precioFinal}
                  onChange={(e) => onChangeItem(e, index)}
                />
              </div>
              <div>
                <textarea
                  name="nota"
                  rows="3"
                  cols="50"
                  placeholder="Descripcion de la Nota"
                  value={item.nota}
                  disabled
                ></textarea>
              </div>
            </div>
            <div>
              <label>terminado</label>
              <input
                name="terminado"
                type="checkbox"
                checked={item.terminado}
                disabled
              ></input>
            </div>
            <div>
              {item.estados && (
                <ul>
                  {item.estados.map((estado, indey) => (
                    <li>{estado}</li>
                  ))}
                </ul>
              )}
            </div>
          </ItemContainer>
        ))}
      </ContainerBody>
      <div>
        <LinkCancelar onClick={onCloseModal}>Cancelar</LinkCancelar>
        <ButtonPagar onClick={onClick}>Procesar</ButtonPagar>
      </div>
    </form>
  );
};
