import { db, firestorage } from "../Config";
//import { useFirebaseApp } from "reactfire";
// función asincrona de fetch ordenes
//const firebase = useFirebaseApp();
export const fetchLabs = () => {
  return new Promise((resolve, reject) => {
    let ordenesRef = db.collection("laboratorios");
    let labs = [];
    ordenesRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          labs.push({
            id: doc.id,
            nombre: doc.data().nombre,
          });
        });
        resolve(labs);
      })
      .catch((err) => reject(err));
  });
};

export const getLabById = (empresaId) => {
  let empresaRef = db.collection("laboratorios").doc(empresaId);
  return new Promise((resolve, reject) => {
    let empresa = {};
    empresaRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          empresa = {
            ruc: doc.data().ruc,
            id: doc.data().id,
            razonSocial: doc.data().razonSocial,
            telefono: doc.data().telefono,
            logoUrl: doc.data().logoUrl,
            googleCalendar: doc.data().googleCalendar,
          };
          resolve(empresa);
        } else {
          reject("no encontro la empresa");
        }
      })
      .catch((err) => {
        reject(err.message);
      });
  });
};

export const saveEmpresa = (emp) => {
  return new Promise((resolve, reject) => {
    const empRef = db.collection("laboratorios").doc(emp.id);
    emp.nombre = emp.razonSocial;
    empRef
      .set(emp)
      .then((docRef) => {
        resolve(true);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const uploadImage = async (file) => {
  const storageRef = firestorage.ref();
  const fileRef = storageRef.child(file.name);
  await fileRef.put(file);
  const fileUrl = await fileRef.getDownloadURL();
  return fileUrl;
};
