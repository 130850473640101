import { db } from "../../services/firebase/Config";

import {
  FETCH_PRESTATIONS_REQUEST,
  FETCH_PRESTATIONS_SUCCESS,
  FETCH_PRESTATIONS_FAILURE,
  ADD_PRESTATION,
  REMOVE_PRESTATION_IN_ARRAY,
  UPDATE_PRESTATION_IN_ARRAY,
  UPDATE_LAB_IN_PRESTATION_IN_ARRAY,
  SAVE_PRESTATION_REQUEST,
  SAVE_PRESTATION_SUCCESS,
  SAVE_PRESTATION_FAILURE,
  NEW_PRESTATIONS,
} from "./prestationsTypes";

import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";
import { setEstadoCompletado } from "../sesion/sesionActions";

const fetchPrestationsRequest = () => {
  return {
    type: FETCH_PRESTATIONS_REQUEST,
  };
};

const fetchPrestationsSuccess = (payload) => {
  return {
    type: FETCH_PRESTATIONS_SUCCESS,
    payload,
  };
};

const fetchPrestationsFailure = (payload) => {
  return {
    type: FETCH_PRESTATIONS_FAILURE,
    payload,
  };
};

// ADD PRESTATION

const savePrestationRequest = () => {
  return {
    type: SAVE_PRESTATION_REQUEST,
  };
};

export const newPrestations = () => {
  return {
    type: NEW_PRESTATIONS,
  };
};

const savePrestationSuccess = () => {
  return {
    type: SAVE_PRESTATION_SUCCESS,
  };
};

const savePrestationFailure = (error) => {
  return {
    type: SAVE_PRESTATION_FAILURE,
    payload: error,
  };
};

export const addPrestation = (payload) => {
  return {
    type: ADD_PRESTATION,
    payload,
  };
};

export const updatePrestationInArray = (payload) => {
  return {
    type: UPDATE_PRESTATION_IN_ARRAY,
    payload,
  };
};

export const updateLabInPrestationInArray = (payload) => {
  return {
    type: UPDATE_LAB_IN_PRESTATION_IN_ARRAY,
    payload,
  };
};

// Remove Prestation
export const removePrestationInArray = (prestation) => {
  return {
    type: REMOVE_PRESTATION_IN_ARRAY,
    payload: prestation,
  };
};

export const addPrestationDb = (prestacion) => {
  return function (dispatch) {
    const prestacionRef = db.collection("prestaciones").doc();
    dispatch(savePrestationRequest());
    prestacion.id = prestacionRef.id;
    prestacionRef
      .set(Object.assign({}, prestacion))
      .then((docRef) => {
        dispatch(savePrestationSuccess());
        dispatch(addPrestation(prestacion));
      })
      .catch((err) => {
        dispatch(savePrestationFailure(err));
      });
  };
};

export const fetchPrestations = (pacienteId) => {
  return function (dispatch) {
    dispatch(fetchPrestationsRequest());
    let prestaciones = [];
    db.collection("prestaciones")
      .where("estado", "in", ["TERMINADO", "ACTIVO", "PENDIENTE"])
      .where("pacienteId", "==", pacienteId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          prestaciones.push({
            id: doc.id,
            tratamiento: doc.data().tratamiento,
            tipo: doc.data().tipo,
            precio: Number(doc.data().precio),
            dientes: doc.data().dientes,
            pagado: Number(doc.data().pagado),
            estado: doc.data().estado,
            estfin: doc.data().estfin,
            caras: doc.data().caras,
            pacienteId: doc.data().pacienteId,
            notasClinicas: doc.data().notasClinicas,
            config: doc.data().config,
            lab: doc.data().lab,
            tratamientoId: doc.data().tratamientoId,
          });
        });
        dispatch(fetchPrestationsSuccess(prestaciones));
        dispatch(
          setEstadoCompletado({
            prestaciones: true,
          })
        );
      })
      .catch((err) => {
        dispatch(fetchPrestationsFailure(err));
        console.log("error getting documento" + err);
      });
  };
};

export const fetchPrestacionesAdeudadas = async (pacienteId) => {
  let prestaciones = [];
  await db
    .collection("prestaciones")
    .where("estfin", "==", "PENDIENTE")
    .where("pacienteId", "==", pacienteId)
    .where("estado", "in", ["TERMINADO", "ACTIVO", "PENDIENTE"])
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        prestaciones.push({
          id: doc.id,
          tratamiento: doc.data().tratamiento,
          tipo: doc.data().tipo,
          precio: Number(doc.data().precio),
          dientes: doc.data().dientes,
          pagado: Number(doc.data().pagado),
          estado: doc.data().estado,
          estfin: doc.data().estfin,
          caras: doc.data().caras,
          pacienteId: doc.data().pacienteId,
          notasClinicas: doc.data().notasClinicas,
          config: doc.data().config,
          estlab: doc.data().estlab,
        });
      });
    })
    .catch((err) => {
      console.log("error getting documento" + err);
    });

  return prestaciones;
};
