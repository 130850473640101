import React from "react";
// import "./css/styles.css";
// import "./css/fontello.css";
import logo from "../../img/logo.png";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Menus } from "../Accesos/index";
import {
  ContenedorHeader,
  HeaderFigure,
  HeaderBar,
  HeaderBarLi,
  HeaderBarUl,
  HeaderBarLink,
  HeaderBarSpan,
} from "./styles";
import { auth } from "../../services/firebase/Config";
import { setUsuarioSesion } from "../../redux/sesion/sesionActions";
import HeaderProfileItem from "./HeaderProfileItem/HeaderProfileItem";

export const Header = () => {
  let { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = () => {
    console.log("presiono clic");
  };

  const sesion = useSelector((state) => state.sesion);
  const MenuSuperior = Menus.find(
    (item) => item.menu === "superior" && item.perfil === "ODONTOLOGO"
  );
  //const logoImg = require(`../../img/logos/${sesion.usuario.empresa.logoNombre}`);
  const logoImg = require(`../../img/logos/clarus_dent.png`);
  const handleCloseSesion = () => {
    dispatch(setUsuarioSesion(null));

    auth.signOut();
    // history.push("/");
    window.location.href = "/";
  };
  if (sesion.loading || sesion.loading === undefined) {
    return "";
  }
  return (
    sesion.usuario && (
      <header>
        <ContenedorHeader>
          {/* <HeaderFigure>
          <img src={logoImg} alt="logo"></img>
        </HeaderFigure> */}

          <HeaderBar>
            <HeaderBarUl onClick={handleClick}>
              {/* {MenuSuperior.items.map((item) => (
              <HeaderBarLi>
                <HeaderBarLink to={item.url}>{item.nombre}</HeaderBarLink>
              </HeaderBarLi>
            ))} */}

              <HeaderProfileItem handleCloseSesion={handleCloseSesion} />
            </HeaderBarUl>
          </HeaderBar>
        </ContenedorHeader>
      </header>
    )
  );
};
