import { db } from "../../services/firebase/Config";
import { SET_LAB } from "./labTypes";
import {
  httpRequest,
  httpSuccess,
  httpFailure,
  setUsuarioSesion,
} from "../sesion/sesionActions";
import { getLabById, saveEmpresa } from "../../services/firebase/labs/index";

export const setLab = (empresa) => {
  return {
    type: SET_LAB,
    payload: empresa,
  };
};

export const getLab = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    getLabById(empresaId)
      .then((empresa) => {
        dispatch(httpSuccess());
        dispatch(setLab(empresa));
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const guardaLab = (empresa) => {
  return function (dispatch) {
    dispatch(httpRequest());
    saveEmpresa(empresa)
      .then(() => {
        dispatch(setLab(empresa));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const guardaLabUsuario = (empresa, usuario) => {
  return function (dispatch) {
    var batch = db.batch();
    let laboratoriosRef = db.collection("laboratorios").doc();
    let usuariosRef = db.collection("usuarios").doc();
    dispatch(httpRequest());
    usuario.labId = laboratoriosRef.id;
    usuario.cargo = "LABORATORISTA";
    usuario.perfil = "ADMIN";
    usuario.fecha = new Date();
    usuario.id = usuariosRef.id;
    empresa.id = laboratoriosRef.id;
    empresa.googleCalendar = usuario.email;
    batch.set(laboratoriosRef, empresa);
    batch.set(usuariosRef, usuario);

    batch
      .commit()
      .then(() => {
        dispatch(setLab(empresa));
        dispatch(setUsuarioSesion(usuario));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};
