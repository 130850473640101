import { db } from "../../services/firebase/Config";
import {
  SET_ORDENES,
  UPDATE_ORDEN_IN_ARRAY,
  ADD_ORDEN_IN_ARRAY,
} from "./ordenesTypes";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";
import { COrden } from "../../Entidades/Orden";
import {
  fetchOrdenes,
  fetchOrdenesEmpresa,
  fetchOrdenesPaciente,
  fetchOrdenesAdeudadas,
} from "../../services/firebase/ordenes";

export const setOrdenes = (ordenes) => {
  return {
    type: SET_ORDENES,
    payload: ordenes,
  };
};

export const updateOrdenInArray = (orden) => {
  return {
    type: UPDATE_ORDEN_IN_ARRAY,
    payload: orden,
  };
};

export const addOrdenInArray = (orden) => {
  return {
    type: ADD_ORDEN_IN_ARRAY,
    payload: orden,
  };
};

export const getOrdenesLab = (labId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenes(labId)
      .then((ordenes) => {
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const getAllOrdenes = () => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenes()
      .then((ordenes) => {
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const getOrdenesEmpresa = (empresaId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenesEmpresa(empresaId)
      .then((ordenes) => {
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};

export const getOrdenesAdeudadas = (labId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenesAdeudadas(labId)
      .then((ordenes) => {
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};
export const getOrdenesPaciente = (pacienteId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    fetchOrdenesPaciente(pacienteId)
      .then((ordenes) => {
        dispatch(setOrdenes(ordenes));
        dispatch(httpSuccess());
      })
      .catch((err) => dispatch(httpFailure(err)));
  };
};
