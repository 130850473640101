import styled from "styled-components";
import { Link } from "react-router-dom";
import { dispositivo } from "../../components/Dispositivos";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";

export const SectionTarjeta = styled.section`
  justify-content: center;
  margin-left: 7%;

  @media ${dispositivo.tablet} {
    flex: 1;
    max-height: 370px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      width: 20px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #fff;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #0c8de3;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #fff;
    }
  }
`;

export const ContenedorTarjeta = styled(Card)`
  display: block;
  padding: 5px;
  max-width: 400px;
  max-height: 400px;
  width: 80%;
  font-size: 10px;
  margin: 1%;
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const ContenedorCabeceraTarjeta = styled(CardHeader)`
  color: #707070;
  background: #fff;
  padding: 2% !important;
`;

export const ContenedorContenidoTarjeta = styled(CardContent)`
  display: block;
  color: #707070;
`;

export const TarjetaAccion = styled(CardActions)`
  justify-content: center;
`;
export const Icono = styled.div`
  margin: 0%;
  cursor: pointer;
`;

export const MainContainer = styled.div`
  width: 90%;
  height: 100%;
  padding-left: 15%;
  padding-right: 15%;
  margin-left: 40px;
  background: #f8f8f8;
  justify-content: center;
  text-align: center;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ButtonAbonar = styled.button`
  background-color: #0c8de3;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 22px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
`;

export const TablaBodyFinanzas = styled.tbody`
  background: #fff;
  padding-left: 2%;
  padding-right: 0%;
  text-align: left;
`;

export const TablaBodyTextoFinanzas = styled.th`
  background: #fff;
  color: #707070;
`;

export const TablaBodyTextoFinanzaPrecio = styled.div`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid #0c8de3;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-right: 5%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBodyTextoFinanzaPagado = styled.div`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid #2cb742;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaBodyTextoSaldo = styled.div`
  font-family: Segoe UI;
  font-weight: normal;
  font-size: 12px;
  padding-left: 2%;
  padding-right: 2%;
  border: 3px solid red;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 10px;
  text-align: center;
  margin-left: 5%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaFinanzas = styled.table`
  margin: 3%;
  width: 72%;
  margin-left: 0%;
  @media ${dispositivo.mobileL} {
    width: 90%;
  }
  @media ${dispositivo.tablet} {
    width: 100%;
  }
`;
export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 2%;
  justify-content: space-between;
`;

export const StyledForm = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Tabla = styled.table`
  width: 90%;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
`;
export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 4%;
  background: #fff;
  box-shadow: 2px 2px #88888877;
  border-radius: 14px;
`;
export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeaderTexto = styled.th`
  font-family: Segoe UI;
  font-size: 12px;
  font-weight: normal;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: center;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaBody = styled.tbody`
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const SelectStyle = styled.select`
  width: 50%;
  vertical-align: middle;
`;

export const StyledInput = styled.input`
  width: 50%;
  margin-bottom: 10px;
  margin-top: 10px;
  vertical-align: middle;
  &:focus {
    outline: none;
  }
`;

export const LinkCancelar = styled(Link)`
  background-color: #0c8de3;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 22px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  vertical-align: middle;
  padding: 0px;
  margin: 0px;
  font-weight: bold;
`;

export const StyledOption = styled.option`
  color: #707070;
  font-size: 12px;
`;

export const StyledOptgroup = styled.optgroup`
  color: #707070;
  font-weight: bold;
  font-size: 12px;
`;

export const ContenedorTabla = styled.div`
  flex: 1;
  max-height: 370px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
`;
