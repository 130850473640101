export const Menus = [
  {
    menu: "lateral",
    key: "lab",
    perfil: "ADMIN",
    nombre: "Ordenes",
    iconFile: "azul_laboratorio.png",
    url: "",
    items: [
      {
        nombre: "Solicitadas",
        iconFile: "azul_ordenes.png",
        url: "/ordenes",
      },
      {
        nombre: "Por cobrar",
        iconFile: "azul_pagos_32.png",
        url: "/porcobrar",
      },
      {
        nombre: "Tarifario",
        iconFile: "azul_pagos_32.png",
        url: "/tarifario",
      },
    ],
  },
  {
    menu: "lateral",
    key: "admin",
    perfil: "ADMIN",
    nombre: "Administracion",
    iconFile: "azul_laboratorio.png",
    url: "",
    items: [
      {
        nombre: "Empresa",
        iconFile: "azul_pagos_32.png",
        url: "/empresa",
      },
    ],
  },
];

export const getMenus = () => {
  return Menus.filter(
    (item) => item.menu === "lateral" && item.perfil === "ADMIN"
  );
};
