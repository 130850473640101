import React from "react";

import {
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  Tabla,
  LinkCancelar,
  ContainerBody,
  Select,
  ButtonPagar,
} from "./styles";

export const ProcesaOrdenEnLab = ({
  orden,
  onCloseModal,
  refOrden,
  onClick,
  onChangeOrden,
  title,
}) => {
  return (
    <form ref={refOrden}>
      <ContainerTitle>
        <ContainerTitleText>{title}</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <Tabla>
          <TablaBody>
            <tr>
              <TablaHeaderTextoItems>Laboratorio</TablaHeaderTextoItems>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Envío: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="envio">
                <input
                  type="date"
                  value={orden.fecEnv}
                  name="fecEnv"
                  required
                  disabled
                ></input>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fecha Prueba: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="recep">
                <input
                  type="date"
                  value={orden.fecRec}
                  name="fecRec"
                  onChange={onChangeOrden}
                  required
                ></input>
              </TablaHeaderTextoValues>
            </tr>
          </TablaBody>
        </Tabla>

        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Precio</th>
              <th>Nota</th>
            </tr>
          </thead>
          <tbody>
            {orden.items.map((item, index) => (
              <tr>
                <td>{item.nombre}</td>
                <td>
                  <input
                    name="precio"
                    id="precio"
                    type="text"
                    value={item.precio}
                    disabled
                  ></input>
                </td>
                <td>
                  <textarea
                    name="nota"
                    rows="3"
                    cols="50"
                    placeholder="Descripcion de la Nota"
                    value={item.nota}
                    disabled
                  ></textarea>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerBody>
      <div>
        <LinkCancelar onClick={onCloseModal}>Cancelar</LinkCancelar>
        <ButtonPagar onClick={onClick}>Procesar</ButtonPagar>
      </div>
    </form>
  );
};
