import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import allReducers from "./redux/rootReducers";
import App from "./components/App";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { ToastProvider } from "react-toast-notifications";
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./services/firebase/Config";

const store = createStore(
  allReducers,
  composeWithDevTools(applyMiddleware(ReduxThunk))
);

// ReactDOM.render(
//   <Provider store={store}>
//     <ToastProvider autoDismiss>
//       //<FirebaseAppProvider firebaseConfig={firebaseConfig}>
//         <Suspense fallback={"Cargando app"}>
//           <App />
//         </Suspense>
//       </FirebaseAppProvider>
//     </ToastProvider>
//   </Provider>,

//   document.getElementById("root")
// );

ReactDOM.render(
  <Provider store={store}>
    <ToastProvider autoDismiss>
      <App />
    </ToastProvider>
  </Provider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
