import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../Modal/index";

import { useToasts } from "react-toast-notifications";

import {
  getPrecio as getPrecioLab,
  getPrecios,
} from "../../services/firebase/tarifarios/index";

import {
  getOrden,
  setOrden,
  nuevaOrden,
  actualizaOrden,
  sendOrden,
  cambiaEstadoOrden,
} from "../../redux/orden/ordenActions";

import { VerOrden } from "./VerOrden";
import { EditaOrdenLab } from "./EditaOrdenLab";
import { TerminaOrden } from "./TerminaOrden";
import { ProcesaOrdenEnLab } from "./ProcesaOrdenEnLab";
import { AceptaOrdenEnLab } from "./AceptaOrdenEnLab";
import { ESTADOS } from "../../Entidades/Orden";

const EVENTOS = {
  EDITAR_ORDEN_EN_CLI: "EDITAR_ORDEN_EN_CLI",
  EDITAR_ORDEN_EN_LAB: "EDITAR_ORDEN_EN_LAB",
  NUEVA_ORDEN_EN_CLI: "NUEVA_ORDEN_EN_CLI",
  VER_ORDEN: "VER_ORDEN",
  ENVIAR_ORDEN_A_LAB: "ENVIAR_ORDEN_A_LAB",
  ENVIAR_ORDEN_A_CLI: "ENVIAR_ORDEN_A_CLI",
  INICIAR_ORDEN_EN_LAB: "INICIAR_ORDEN_EN_LAB",
  TERMINAR_ORDEN: "TERMINAR_ORDEN",
  AUTORIZAR_ORDEN: "AUTORIZAR_ORDEN",
};

export const OrdenModalContainer = (props) => {
  const sesion = useSelector((state) => state.sesion);
  const paciente = useSelector((state) => state.paciente);
  const orden = useSelector((state) => state.orden);
  const lab = useSelector((state) => state.lab);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  //const [itemsOrden, setItemsOrden] = React.useState(orden.items);
  const [itemPrestacion, setItemPrestacion] = React.useState({});
  const frmOrden = React.useRef();
  const handleChangeOrden = (e) => {
    dispatch(setOrden({ ...orden, [e.target.name]: e.target.value }));
  };

  const handleChangeLabId = (e) => {
    actualizaPrecioLab(e.target.value);
    dispatch(setOrden({ ...orden, [e.target.name]: e.target.value }));
  };

  const handleChangeItem = (e, index) => {
    let values = [...orden.items];
    if (e.target.name == "terminado")
      values[index]["terminado"] = e.target.checked;
    else if (e.target.name == "precioFinal")
      values[index][e.target.name] = Number(e.target.value);
    else values[index][e.target.name] = e.target.value;

    dispatch(setOrden({ ...orden, items: values }));
  };

  const handleAddEstado = (e, index) => {
    e.preventDefault();
    let values = orden.items;
    values[index]["estados"].push(values[index]["nuevoEstado"]);
    dispatch(setOrden({ ...orden, items: values }));
  };

  const handleRemoveEstado = (index, indey) => {
    let values = orden.items;
    values[index]["estados"].splice(indey, 1);
    dispatch(setOrden({ ...orden, items: values }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (
      !frmOrden.current.checkValidity() &&
      !frmOrden.current.reportValidity()
    ) {
      return;
    }
    try {
      orden.precio = getPrecio();
      if (
        props.accion == EVENTOS.NUEVA_ORDEN_EN_CLI ||
        props.accion == EVENTOS.EDITAR_ORDEN_EN_CLI
      )
        validar(orden);
      switch (props.accion) {
        case EVENTOS.NUEVA_ORDEN_EN_CLI:
          saveNew();
          break;
        case EVENTOS.EDITAR_ORDEN_EN_CLI:
          saveEdit();
          break;
        case EVENTOS.EDITAR_ORDEN_EN_LAB:
          saveEdit();
          break;
        case EVENTOS.ENVIAR_ORDEN_A_LAB:
          saveSend("LAB");
          break;
        case EVENTOS.ENVIAR_ORDEN_A_CLI:
          saveSend("CLI");
          break;
        case EVENTOS.INICIAR_ORDEN_EN_LAB:
          saveInicia("CLI");
          break;
        case EVENTOS.TERMINAR_ORDEN:
          saveTerminar();
          break;
        case EVENTOS.AUTORIZAR_ORDEN:
          saveAutorizar();
          break;
        default:
          throw new Error("fallo");
      }

      addToast("Grabación Satisfactoria", { appearance: "success" });
      props.handleCloseModal();
    } catch (err) {
      addToast(err.message, { appearance: "error" });
    }
  };

  const saveNew = () => {
    completaDatos(orden);

    orden.estado = ESTADOS.PENDIENTE;
    dispatch(nuevaOrden(orden));
  };

  const saveEdit = () => {
    orden.cambioPrecio = cambioPrecio();
    dispatch(actualizaOrden(orden));
  };

  const saveTerminar = () => {
    //verifica si se ha completado todas las prestaciones
    let noTerminado = orden.items.some((item) => !item.terminado);
    if (!noTerminado) orden.estado = ESTADOS.TERMINADO;
    dispatch(actualizaOrden(orden));
  };

  const saveAutorizar = () => {
    //verifica si( se ha completado todas las prestaciones

    orden.items = orden.items.map((item) => {
      item.precio = item.precioFinal > 0 ? item.precioFinal : item.precio;
      return item;
    });
    if (orden.items.length == 0) throw new Error("No se encontraron items");
    orden.precio = getPrecio();
    orden.cambioPrecio = cambioPrecio();
    orden.estado = ESTADOS.PROCESO;
    dispatch(actualizaOrden(orden));
  };

  const saveSend = () => {
    if (orden.estado === ESTADOS.PENDIENTE) orden.estado = ESTADOS.SOLICITADO;
    if (orden.estado === ESTADOS.PROCESO) orden.estado = ESTADOS.PRUEBAS;

    dispatch(sendOrden(orden));
  };

  const saveInicia = () => {
    orden.cambioPrecio = cambioPrecio();
    if (!orden.cambioPrecio) orden.estado = ESTADOS.PROCESO;
    dispatch(actualizaOrden(orden));
  };
  const saveRecibe = () => {
    dispatch(cambiaEstadoOrden(orden, ESTADOS.PRUEBAS));
  };
  const getPrecio = () => {
    let precio = 0;
    if (orden.items.length > 0) {
      precio = orden.items
        .map((item) => item.precio)
        .reduce((total, i) => total + i);
    }

    return precio;
  };
  const cambioPrecio = () => {
    let cambio = orden.items.some((item) => item.precioFinal);
    if (cambio) {
      cambio = false;
      cambio = orden.items.some((item) => item.precio != item.precioFinal);
    }
    return cambio;
  };
  const completaDatos = (orden) => {
    orden.pacienteId = paciente.id;
    orden.pacienteNombres = `${paciente.nombres} ${paciente.apellidos}`;
    orden.empresa = sesion.usuario.empresa;
    orden.labNombre = "PRUEBA";
    orden.precio = getPrecio();
    orden.empresaId = sesion.usuario.empresa.codigo;
  };
  const validar = (orden) => {
    let fecEnv = new Date(orden.fecEnv);
    if (props.accion == EVENTOS.ENVIAR_ORDEN_A_LAB) {
      if (!orden.fecRec) throw new Error("Fecha de recepción inválida");
      if (!orden.fecEnv) throw new Error("Fecha de envío inválida");
    }
    let fecRec = new Date(orden.fecRec);
    if (fecRec < fecEnv)
      throw new Error("Fecha recepción debe ser mayor a la fecha de envío");
    if (orden.labId === null || orden.labId === "")
      throw new Error("Debe seleccionar laboratorio");
    if (orden.items.length == 0) throw new Error("Ingrese items a la orden");
    if (isNaN(orden.precio) || Number(orden.precio) <= 0)
      throw new Error("Precio inválido");
  };
  const handleRemove = (id) => {
    let items = orden.items.filter((it) => it.id != id);
    dispatch(setOrden({ ...orden, items: items }));
    //setItemsOrden(items);
  };

  const actualizaPrecioLab = () => {
    let myItemsOrden = [...orden.items];
    myItemsOrden.forEach((it) => {
      if (it.precios)
        it.precio = it.precios.find((i) => i.labId === orden.labId).precio;
      else
        getPrecios(it.codigo).then((precios) => {
          it.precio = precios.find((i) => i.labId === orden.labId).precio;
          it.precios = precios;
        });
    });
    dispatch(setOrden({ ...orden, items: myItemsOrden }));
    //setItemsOrden(myItemsOrden);
  };
  const handleAddPrestacion = () => {
    if (!itemPrestacion.id) {
      addToast("Seleccione prestación", { appearance: "error" });
      return;
    }
    let existe = orden.items.some((it) => it.id == itemPrestacion.id);
    if (!existe) {
      if (!orden.labId) {
        addToast("seleccione laboratorio", { appearance: "error" });
        return;
      }
      getPrecios(String(itemPrestacion.lab.codLab))
        .then((precios) => {
          let precio = precios.find((it) => it.labId == orden.labId).precio;
          if (precio == null || precio == 0)
            addToast("Tratamiento no tiene codigo de laboratorio", {
              appearance: "error",
            });
          else
            dispatch(
              setOrden({
                ...orden,
                items: [
                  {
                    id: itemPrestacion.id,
                    nombre: `${itemPrestacion.tratamiento} ${
                      itemPrestacion.tipo ? itemPrestacion.tipo : ""
                    }`,
                    dientes: `${
                      itemPrestacion.dientes.length > 0
                        ? itemPrestacion.dientes.join()
                        : ""
                    }`,
                    precios: precios,
                    precio: precio,
                    precioFinal: precio,
                    codLab: itemPrestacion.lab.codLab,
                    nota: "",
                    estados: [],
                    nuevoEstado: "",
                    terminado: false,
                  },
                  ...orden.items,
                ],
              })
            );
        })
        .catch((err) => addToast(err.message, { appearance: "error" }));
    }
  };
  let OrdenComp;

  switch (props.accion) {
    case EVENTOS.EDITAR_ORDEN_EN_LAB:
      OrdenComp = (
        <EditaOrdenLab
          orden={orden}
          onChangeOrden={handleChangeOrden}
          onSave={handleSave}
          onCloseModal={props.handleCloseModal}
          item={itemPrestacion}
          onChangeItem={handleChangeItem}
          refOrden={frmOrden}
          onAddEstado={handleAddEstado}
          onRemoveEstado={handleRemoveEstado}
        />
      );
      break;

    case EVENTOS.VER_ORDEN:
      OrdenComp = (
        <VerOrden orden={orden} onCloseModal={props.handleCloseModal} />
      );
      break;
    case EVENTOS.ENVIAR_ORDEN_A_CLI:
      OrdenComp = (
        <ProcesaOrdenEnLab
          orden={orden}
          onCloseModal={props.handleCloseModal}
          onChangeOrden={handleChangeOrden}
          onClick={handleSave}
          refOrden={frmOrden}
          title="Enviar Orden"
        />
      );
      break;
    case EVENTOS.INICIAR_ORDEN_EN_LAB:
      OrdenComp = (
        <AceptaOrdenEnLab
          orden={orden}
          onCloseModal={props.handleCloseModal}
          onChangeOrden={handleChangeOrden}
          onClick={handleSave}
          refOrden={frmOrden}
          title="Iniciar Orden"
          onChangeItem={handleChangeItem}
        />
      );
      break;
    case EVENTOS.TERMINAR_ORDEN:
      OrdenComp = (
        <TerminaOrden
          orden={orden}
          onCloseModal={props.handleCloseModal}
          onChangeOrden={handleChangeOrden}
          onClick={handleSave}
          refOrden={frmOrden}
          onChangeItem={handleChangeItem}
        />
      );
      break;
    default:
      OrdenComp = (
        <VerOrden orden={orden} onCloseModal={props.handleCloseModal} />
      );
  }

  if (sesion.loading || sesion.loading == undefined)
    return <div>...cargando</div>;
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  return (
    <Modal isOpen={props.isOpen} onClose={props.handleCloseModal}>
      {OrdenComp}
    </Modal>
  );
};
