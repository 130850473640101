import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOrden } from "../../redux/orden/ordenActions";
import {
  getOrdenesEmpresa,
  getOrdenesAdeudadas,
} from "../../redux/ordenes/ordenesActions";
import { getEmpresas } from "../../services/firebase/empresas/index";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import LaunchIcon from "@material-ui/icons/Launch";
import VisibilityIcon from "@material-ui/icons/Visibility";

import Zoom from "@material-ui/core/Zoom";
import { OrdenModalContainer } from "../../components/OrdenModalContainer";
import {
  MainContainer,
  ContainerTitle,
  BodyContainer,
  LinkCancelar,
  StyledForm,
  StyledOption,
  StyledOptgroup,
  ButtonAbonar,
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  SelectStyle,
  StyledInput,
  StyledLabel,
  ContainerTitleText,
  ContenedorTabla,
  ContainerBody,
  SectionTarjeta,
  ContenedorContenidoTarjeta,
  ContenedorCabeceraTarjeta,
  TarjetaAccion,
  ToolTip,
  TablaBodyFinanzas,
  TablaBodyTextoFinanzas,
  TablaBodyTextoFinanzaPrecio,
  TablaBodyTextoFinanzaPagado,
  TablaBodyTextoSaldo,
  TablaFinanzas,
  ContenedorTarjeta,
} from "./styles";
import { useToasts } from "react-toast-notifications";
import { ESTADOS } from "../../Entidades/Orden";
const EVENTOS = {
  EDITAR_ORDEN_EN_LAB: "EDITAR_ORDEN_EN_LAB",
  VER_ORDEN: "VER_ORDEN",
  ENVIAR_ORDEN_A_CLI: "ENVIAR_ORDEN_A_CLI",
  INICIAR_ORDEN_EN_LAB: "INICIAR_ORDEN_EN_LAB",
};
const Fila = ({ item, handleEvento }) => {
  return (
    <tr key={item.id}>
      <td data-label="FecEnvio">{item.fecEnv}</td>
      <td data-label="Numero">{item.numCor}</td>
      <td data-label="Clinica">{item.empresa.razonSocial}</td>
      <td data-label="Paciente">{item.pacienteNombres}</td>
      <td data-label="Deuda">{item.precio}</td>
      <td data-label="Estado">{item.estado}</td>
    </tr>
  );
};

export const Finanzas = ({ precio, pagado }) => (
  <TablaFinanzas>
    <TablaBodyFinanzas>
      <tr>
        <td>
          <TablaBodyTextoFinanzaPrecio>
            Precio: S/ {Number(precio)?.toFixed(2)}
          </TablaBodyTextoFinanzaPrecio>
        </td>
        <td>
          <TablaBodyTextoFinanzaPagado>
            Pagado: S/ {Number(pagado)?.toFixed(2)}
          </TablaBodyTextoFinanzaPagado>
        </td>
        <td>
          <TablaBodyTextoSaldo>
            Saldo: S/ {Number(precio - pagado)?.toFixed(2)}
          </TablaBodyTextoSaldo>
        </td>
      </tr>
    </TablaBodyFinanzas>
  </TablaFinanzas>
);
export const OrdenesTable = ({ items, handleEvento }) => {
  return (
    <Tabla>
      <TablaHeader>
        <tr>
          <TablaHeaderTexto scope="col">Fec. Envio</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Numero</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Clinica</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Paciente</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Precio</TablaHeaderTexto>
          <TablaHeaderTexto scope="col">Estado</TablaHeaderTexto>
        </tr>
      </TablaHeader>
      <TablaBody>
        {items.map((item) => {
          return <Fila key={item.id} item={item} handleEvento={handleEvento} />;
        })}
      </TablaBody>
    </Tabla>
  );
};

const useOrdenes = (ordenes) => {
  const [empresaId, setEmpresaId] = React.useState("");
  ordenes.sort(function (a, b) {
    if (a.fecEnv > b.fecEnv) {
      return 1;
    }
    if (a.fecEnv < b.fecEnv) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  const [filteredItems, setFilteredItems] = React.useState(ordenes);

  React.useMemo(() => {
    let result = ordenes;

    if (empresaId && ordenes.length > 0) {
      if (empresaId === "XXX") result = ordenes;
      else
        result = ordenes.filter((orden) => {
          return (
            orden.empresaId == empresaId &&
            (orden.estado != ESTADOS.TERMINADO ||
              orden.estado != ESTADOS.PENDIENTE)
          );
        });
    }

    setFilteredItems(result);
  }, [ordenes, empresaId]);
  return [filteredItems, empresaId, setEmpresaId];
};
export const PorCobrar = () => {
  const [estado, setEstado] = React.useState({
    loading: true,
    empresas: [],
    empresaId: "",
    ordenes: [],
    error: "",
  });

  const [accion, setAccion] = React.useState("");
  const ordenes = useSelector((state) => state.ordenes);
  const orden = useSelector((state) => state.orden);
  const sesion = useSelector((state) => state.sesion);
  const lab = useSelector((state) => state.lab);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [ordenesFiltradas, empresaId, setEmpresaId] = useOrdenes(ordenes);
  React.useEffect(() => {
    getEmpresas().then((empresas) =>
      setEstado({ ...estado, empresas: empresas })
    );
    if (ordenes.length == 0) dispatch(getOrdenesAdeudadas(lab.id));
  }, []);

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
  };

  const handleChange = (e) => {
    setEmpresaId(e.target.value);
    //dispatch(getOrdenesEmpresa(e.target.value));
  };
  const handleCloseModal = (e) => {
    setModalIsOpen(false);
  };
  const handleEnviar = (item) => {
    //item.fecRec = sesion.fecha;
    //dispatch(getPrestacion(item.id));
    setAccion(EVENTOS.ENVIAR_ORDEN_A_CLI);
    dispatch(setOrden(item));
    setModalIsOpen(true);
  };

  const handleEvento = (item, evento) => {
    setAccion(evento);
    dispatch(setOrden(item));
    setModalIsOpen(true);
  };

  const handleRefrescar = () => {
    dispatch(getOrdenesAdeudadas(lab.id));
  };

  if (sesion.loading || sesion.loading == undefined)
    return <div>...cargando</div>;
  else if (sesion.error) return <div>error + {sesion.error}</div>;
  return (
    <MainContainer>
      <ContainerTitle>
        <ContainerTitleText>Ordenes Solicitadas</ContainerTitleText>
      </ContainerTitle>
      <BodyContainer>
        <div>
          <button onClick={handleRefrescar}>Refrescar</button>
        </div>
        <div>
          <select
            id="empresaId"
            name="empresaId"
            value={empresaId}
            onChange={handleChange}
            onSelected={handleChange}
            required
          >
            <option value="XXX" selected>
              Todas
            </option>
            {estado.empresas.map((empresa) => (
              <option value={empresa.id}>{empresa.razonSocial}</option>
            ))}
          </select>
        </div>
      </BodyContainer>

      <ContainerBody>
        <ContenedorTabla>
          <OrdenesTable items={ordenesFiltradas} handleEvento={handleEvento} />
        </ContenedorTabla>
      </ContainerBody>
    </MainContainer>
  );
};
