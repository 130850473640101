import React from "react";

import {
  ContainerTitle,
  ContainerTitleText,
  TablaBody,
  TablaHeaderTextoItems,
  TablaHeaderTextoValues,
  Tabla,
  LinkCancelar,
  ContainerBody,
  Select,
  ButtonPagar,
  ItemContainer,
} from "./styles";

export const TerminaOrden = ({
  orden,
  onCloseModal,
  refOrden,
  onClick,
  onChangeOrden,
  onChangeItem,
}) => {
  return (
    <form ref={refOrden}>
      <ContainerTitle>
        <ContainerTitleText>Terminar Orden</ContainerTitleText>
      </ContainerTitle>
      <ContainerBody>
        <Tabla>
          <TablaBody>
            <tr>
              <TablaHeaderTextoItems>Laboratorio</TablaHeaderTextoItems>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fec. Envío: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="envio">
                <input
                  type="date"
                  value={orden.fecEnv}
                  name="fecEnv"
                  required
                  disabled
                ></input>
              </TablaHeaderTextoValues>
            </tr>
            <tr>
              <TablaHeaderTextoItems>
                <strong>Fecha Prueba: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="recep">
                <input
                  type="date"
                  value={orden.fecRec}
                  name="fecRec"
                  onChange={onChangeOrden}
                  required
                ></input>
              </TablaHeaderTextoValues>
            </tr>
            {/* <tr>
              <TablaHeaderTextoItems>
                <strong>Nota Recepcion: </strong>
              </TablaHeaderTextoItems>
              <TablaHeaderTextoValues data-label="recep">
                <input
                  type="text"
                  value={orden.notaRec}
                  name="notaRec"
                  onChange={onChangeOrden}
                  required
                ></input>
              </TablaHeaderTextoValues>
            </tr> */}
          </TablaBody>
        </Tabla>

        {orden.items.map((item, index) => (
          <ItemContainer>
            <div>{` ${item.nombre} dientes:[${item.dientes}]`}</div>
            <div>
              <div>
                <input
                  type="text"
                  name="precio"
                  value={item.precio}
                  onChange={(e) => onChangeItem(e, index)}
                  disabled
                />
              </div>
              <div>
                <textarea
                  name="nota"
                  rows="3"
                  cols="50"
                  placeholder="Descripcion de la Nota"
                  value={item.nota}
                  disabled
                ></textarea>
              </div>
            </div>

            <div>
              {item.estados && (
                <ul>
                  {item.estados.map((estado, indey) => (
                    <li>{estado} </li>
                  ))}
                </ul>
              )}
            </div>
            <div>
              <input
                name="terminado"
                type="checkbox"
                checked={item.terminado}
                onChange={(e) => onChangeItem(e, index)}
              ></input>
            </div>
          </ItemContainer>
        ))}
      </ContainerBody>
      <div>
        <LinkCancelar onClick={onCloseModal}>Cancelar</LinkCancelar>
        <ButtonPagar onClick={onClick}>Guardar</ButtonPagar>
      </div>
    </form>
  );
};
