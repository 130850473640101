import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import {
  fetchTarifarios,
  actualizaItem as actualizaItemDb,
  removeItem,
  addItem as addItemDb,
} from "../../services/firebase/tarifarios/index";
import { fetchLabs } from "../../services/firebase/labs";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";

import {
  Tabla,
  TablaHeader,
  TablaHeaderTexto,
  TablaBody,
  TablaBodyTexto,
  StyledInput,
  MainContainer,
  ContainerTitle,
  ContainerTitleText,
  ContainerBody,
  StyledLabel,
} from "./styles";

const Fila = ({ item, idx, onChange, labId }) => {
  const history = useHistory();
  let ele = item.precios.find((it) => it.labId === labId);
  return (
    <tr>
      <TablaBodyTexto data-label="Nombre">{item.nombre}</TablaBodyTexto>
      <TablaBodyTexto data-label="Precio">
        <input
          type="number"
          name="precios"
          value={ele ? ele.precio : ""}
          onChange={(e) => onChange(item, idx, e)}
          disabled={labId ? false : true}
        ></input>
      </TablaBodyTexto>
    </tr>
  );
};

export const Tarifario = () => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  const sesion = useSelector((state) => state.sesion);
  const history = useHistory();
  const [temporizadores, setTemporizadores] = React.useState([
    { id: "", funcion: undefined },
  ]);
  const [estado, setEstado] = React.useState({
    loading: false,
    labId: "",
    tarifarios: [],
    error: "",
  });
  const lab = useSelector((state) => state.lab);
  const { addToast } = useToasts();
  const handleClick = (item) => {
    history.push({ pathname: "config", state: { data: item } });
  };
  React.useEffect(() => {
    const traerTarifarios = async () => {
      setEstado({ ...estado, loading: true });
      let tarifarios = await fetchTarifarios();
      setEstado({ ...estado, tarifarios: tarifarios, loading: false });
    };
    traerTarifarios();
  }, []);

  const addTemporizador = (item) => {
    //buscar si existe temporizador
    const idx = temporizadores.findIndex((t) => t.id == item.id);
    // si no existe lo crea
    if (idx < 0)
      setTemporizadores([
        ...temporizadores,
        {
          id: item.id,
          funcion: setTimeout(() => actualizaItem(item), 2000),
        },
      ]);
    else {
      if (temporizadores[idx].funcion) {
        console.log("se cancela anterior");
        clearTimeout(temporizadores[idx].funcion);
      }
      let temp = temporizadores;
      temp[idx] = {
        id: item.id,
        funcion: setTimeout(() => actualizaItem(item), 3000),
      };
      setTemporizadores(temp);
    }
  };

  const actualizaItem = async (item) => {
    setEstado({ ...estado, loading: true });
    await actualizaItemDb(item);
    setEstado({ ...estado, loading: false });
  };

  const handleChange = (item, idx, e) => {
    let tarifarios = estado.tarifarios;
    const idy = item.precios.findIndex((t) => t.labId == lab.id);
    if (idy > -1)
      tarifarios[idx][e.target.name][idy]["precio"] = Number(e.target.value);
    else
      tarifarios[idx]["precios"].push({
        labId: lab.id,
        precio: Number(e.target.value),
      });
    setEstado({ ...estado, tarifarios: tarifarios });
    addTemporizador(item);
  };

  if (estado.loading || estado.loading == undefined)
    return <div>...actualizando</div>;
  else if (estado.error) return <div>error + {estado.error}</div>;
  else
    return (
      <MainContainer>
        <ContainerTitle>
          <ContainerTitleText>Tarifario</ContainerTitleText>
        </ContainerTitle>

        <ContainerBody>
          <Tabla>
            <TablaHeader>
              <tr>
                <TablaHeaderTexto scope="col">Nombre</TablaHeaderTexto>
                <TablaHeaderTexto scope="col">Precio</TablaHeaderTexto>
              </tr>
            </TablaHeader>
            <TablaBody>
              {estado.tarifarios.map((item, idx) => {
                return (
                  <Fila
                    key={item.id}
                    item={item}
                    idx={idx}
                    onChange={handleChange}
                    labId={lab.id}
                  />
                );
              })}
            </TablaBody>
          </Tabla>
        </ContainerBody>
      </MainContainer>
    );
};
