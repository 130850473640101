export const FETCH_PRESTATIONS_REQUEST = "FETCH_PRESTATIONS_REQUEST";
export const FETCH_PRESTATIONS_SUCCESS = "FETCH_PRESTATIONS_SUCCESS";
export const FETCH_PRESTATIONS_FAILURE = "FETCH_PRESTATIONS_FAILURE";
export const ADD_PRESTATION = "ADD_PRESTATION";
export const REMOVE_PRESTATION_IN_ARRAY = "REMOVE_PRESTATION_IN_ARRAY";
export const UPDATE_PRESTATION_IN_ARRAY = "UPDATE_PRESTATION_IN_ARRAY";
export const UPDATE_LAB_IN_PRESTATION_IN_ARRAY =
  "UPDATE_LAB_IN_PRESTATION_IN_ARRAY";
export const SAVE_PRESTATION_REQUEST = "SAVE_PRESTATION_REQUEST";
export const SAVE_PRESTATION_SUCCESS = "SAVE_PRESTATION_SUCCESS";
export const ADD_PRESTATION_SUCCESS = "ADD_PRESTATION_SUCCESS";
export const UPDATE_PRESTATION_SUCCESS = "UPDATE_PRESTATION_SUCCESS";
export const SAVE_PRESTATION_FAILURE = "SAVE_PRESTATION_FAILURE";
export const NEW_PRESTATIONS = "NEW_PRESTATIONS";
