import styled from "styled-components";
import { dispositivo } from "../Dispositivos";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

export const TablaNota = styled.table`
  justify-content: center;
  margin-left: 1%;
  margin-bottom: 1%;
  @media ${dispositivo.mobileL} {
    margin-left: 3%;
    margin-bottom: 2%;
  }
  @media ${dispositivo.tablet} {
    margin-left: 5%;
    margin-bottom: 3%;
  }
`;

export const LabelNota = styled.td`
  text-align: left;
`;

export const FlowContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`;
export const Select = styled.select`
  max-width: 200px;
`;

export const AddButton = styled(Button)`
  && {
    background-color: #0c8de3;
    border: none;
    color: aquamarine;
    text-align: center;
    font-size: 10px;
    padding-bottom: 0.8%;
    border-radius: 4px;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
    @media ${dispositivo.mobileL} {
      font-size: 12px;
    }
    @media ${dispositivo.tablet} {
      font-size: 14px;
    }
  }
`;

export const ButtonActionNota = styled(Button)`
  && {
    background-color: #0c8de3;
    border: none;
    color: white;
    text-align: center;
    font-size: 10px;
    margin: 2%;
    padding-bottom: 0.8%;
    border-radius: 4px;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    @media ${dispositivo.mobileL} {
      font-size: 12px;
    }
    @media ${dispositivo.tablet} {
      font-size: 14px;
    }
  }
`;

export const ContainerNuevaNota = styled.div`
  justify-content: center;
`;

export const ContainerNuevaNotaTitulo = styled.div`
  text-align: center;
  color: #707070;
  background: #fff;
  padding: 1% !important;
`;

export const TextArea = styled.textarea`
  width: 99%;
`;

///////////
export const MainContainer = styled.div`
  height: 100%;
  justify-content: center;
  text-align: center;
`;

export const ContainerTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  text-align: center;
`;

export const ContainerTitleText = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 1.5%;
  background-color: #0c8de3;
  color: white;
  border-radius: 10px;
`;

export const ButtonPagar = styled.button`
  background-color: #0c8de3;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 22px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  :hover {
    background-color: #707070;
    color: white;
  }
`;
export const LinkCancelar = styled.button`
  background-color: #0c8de3;
  border: none;
  color: white;
  text-align: center;
  font-size: 16px;
  margin: 4px 22px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  :hover {
    background-color: #707070;
    color: white;
  }
`;
export const ButtonInsideContainer = styled.div`
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #000;
`;
export const InputButton = styled.input`
  height: 25px;
  width: 100%;
  padding-left: 10px;
  border-radius: 4px;
  border: none;
  outline: none;
`;

export const ButtonInside = styled.button`
  //position: absolute;
  right: 0px;
  top: 4px;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  outline: none;
  text-align: center;
  font-weight: bold;
  padding: 2px;
  &:hover {
    cursor: pointer;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;
export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  justify-content: center;
  text-align: center;
`;

export const StyledForm = styled.form`
  width: 90%;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const Tabla = styled.table`
  width: 100%;
  justify-content: center;
  text-align: center;
`;

export const TablaHeader = styled.thead`
  background: #0c8de3;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: center;
  text-align: center;
`;

export const TablaHeaderTextoItems = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  justify-content: center;
  text-align: left;
  width: 50%;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
export const TablaHeaderTextoValues = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  justify-content: center;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;
export const TablaHeaderTextoButtons = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: center;
  text-align: center;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 16px;
  }
`;

export const TablaHeaderTextoNumber = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaHeaderTexto = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: right;
  text-align: right;

  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const TablaBody = styled.tbody`
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  vertical-align: middle;
`;

export const TablaBodyTexto = styled.th`
  font-size: 12px;
  font-family: Segoe UI;
  font-weight: normal;
  color: #707070;
  padding-top: 8px;
  padding-bottom: 8px;
  vertical-align: middle;
  text-align: left;
  @media ${dispositivo.mobileL} {
    font-size: 14px;
  }
  @media ${dispositivo.tablet} {
    font-size: 18px;
  }
`;

export const SelectStyle = styled.select`
  width: 50%;
  vertical-align: middle;
`;

export const StyledInput = styled.input`
  width: 45%;
  color: #707070;
  vertical-align: middle;
  text-align: right;
  padding-left: 12px;
  padding-right: 0px;
  &:focus {
    outline: none;
  }
`;

export const TablaButtons = styled.table`
  width: 90%;
  justify-content: center;
  text-align: center;
  @media ${dispositivo.tablet} {
    width: 100%;
  }
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  background: #fff;
  display: block;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #0c8de3;
`;
