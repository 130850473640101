import { db } from "../Config";

export const getEmpresa = (empresaId) => {
  let empresa;
  let empresaRef = db.collection("empresas").doc(empresaId);
  empresaRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        empresa = {
          RUC: doc.data().RUC,
          codigo: doc.data().codigo,
          id: doc.data().id,
          logoNombre: doc.data().logoNombre,
          razonSocial: doc.data().razonSocial,
          telefono: doc.data().telefono,
        };
      } else {
        console.log("no encontro la empresa");
      }
    })
    .catch((err) => {});
};

export const getEmpresas = () => {
  return new Promise((resolve, reject) => {
    let empresas = [];

    let empresasRef = db.collection("empresas");
    empresasRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          empresas.push(doc.data());
        });
        resolve(empresas);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
