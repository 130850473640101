export class COrden {
  constructor(
    id = "",
    estado = "PENDIENTE",
    fecEnv = "",
    fecRec = "",
    labId = "",
    labNombre = "",
    pacienteId = "",
    pacienteNombres = "",
    precio = 0,
    pagado = 0,
    estFin = "PENDIENTE",
    notaRec = "",
    numCor = "",
    empresaId = "",
    items = [],
    empresa = {},
    cambioPrecio = false
  ) {
    this.id = id;
    this.estado = estado;
    this.fecEnv = fecEnv;
    this.fecRec = fecRec;
    this.labId = labId;
    this.labNombre = labNombre;
    this.pacienteId = pacienteId;
    this.pacienteNombres = pacienteNombres;
    this.precio = precio;
    this.pagado = pagado;
    this.estFin = estFin;
    this.notaRec = notaRec;
    this.numCor = numCor;
    this.empresaId = empresaId;
    this.items = items;
    this.empresa = empresa;
    this.cambioPrecio = cambioPrecio;
  }
  getFecha() {
    const fecha = new Date();
    const a = fecha.getFullYear();
    const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
    const d = fecha.getDate().toString().padStart(2, "0");
    return `${a}-${m}-${d}`;
  }
}

export const ESTADOS = {
  SOLICITADO: "SOLICITADO",
  PENDIENTE: "PENDIENTE",
  PROCESO: "PROCESO",
  TRANSITO: "TRANSITO",
  PRUEBAS: "PRUEBAS",
  TERMINADO: "TERMINADO",
};
