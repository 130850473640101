import { db } from "../../services/firebase/Config";
import { SET_ORDEN, NEW_ORDEN } from "./ordenTypes";
import { httpRequest, httpSuccess, httpFailure } from "../sesion/sesionActions";
import { recibirOrdenDb, enviarOrdenDb } from "../../services/firebase/ordenes";
import { updateOrdenInArray, addOrdenInArray } from "../ordenes/ordenesActions";

import { updateLabInPrestationInArray } from "../prestations/prestationsActions";

import { COrden } from "../../Entidades/Orden";
export const setOrden = (orden) => {
  return {
    type: SET_ORDEN,
    payload: orden,
  };
};
export const newOrden = () => {
  return {
    type: NEW_ORDEN,
  };
};
export const getOrden = (ordenId) => {
  return function (dispatch) {
    dispatch(httpRequest());
    let orden;
    let ordenRef = db.collection("ordenes").doc(ordenId);
    ordenRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          orden = {
            id: doc.id,
            estado: doc.data().estado,
            fecEnv: doc.data().fecEnv,
            fecRec: doc.data().fecRec,
            labId: doc.data().labId,
            labNombre: doc.data().labNombre,
            pacienteId: doc.data().pacienteId,
            pacienteNombres: doc.data().pacienteNombres,
            precio: doc.data().precio,
            pagado: doc.data().pagado,
            estFin: doc.data().estFin,
            notaRec: doc.data().notaRec,
            numCor: doc.data().numCor,
            empresaId: doc.data().empresaId,
          };
          console.log(orden);
          dispatch(setOrden(orden));
          dispatch(httpSuccess());
        } else {
          console.log("no encontro la orden");
          orden = new COrden();
          dispatch(setOrden(orden));
          dispatch(httpSuccess());
        }
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

var ordenConverter = {
  toFirestore: function (orden) {
    return {
      id: orden.id,
      estado: orden.estado,
      fecEnv: orden.fecEnv,
      fecRec: orden.fecRec,
      labId: orden.labId,
      labNombre: orden.labNombre,
      pacienteId: orden.pacienteId,
      pacienteNombres: orden.pacienteNombres,
      precio: Number(orden.precio),
      pagado: Number(orden.pagado),
      estFin: orden.estFin,
      notaRec: orden.notaRec,
      numCor: orden.numCor,
      empresaId: orden.empresaId,
      items: orden.items,
      empresa: orden.empresa,
      cambioPrecio: orden.cambioPrecio,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return {
      id: data().id,
      estado: data().estado,
      fecEnv: data().fecEnv,
      fecRec: data().fecRec,
      labId: data().labId,
      labNombre: data().labNombre,
      pacienteId: data().pacienteId,
      pacienteNombres: data().pacienteNombres,
      precio: data().precio,
      pagado: data().pagado,
      estFin: data().estFin,
      notaRec: data().notaRec,
      empresaId: data().empresaId,
      items: data().items,
      empresa: data().empresa,
      cambioPrecio: data().cambioPrecio ? data().cambioPrecio : false,
    };
  },
  toDate(fecha) {
    if (typeof fecha === "string") return new Date(fecha);
  },
  toCadena(fecha) {
    if (typeof fecha === "object") {
      const a = fecha.getFullYear();
      const m = (fecha.getMonth() + 1).toString().padStart(2, "0");
      const d = fecha.getDate().toString().padStart(2, "0");
      return `${a}-${m}-${d}`;
    }
  },
};
const nextOrden = async (empresaId, año) => {
  const secuenciasRef = db.collection("secuencias");
  let valor = 0;
  let docRef;
  await secuenciasRef
    .where("empresaId", "==", empresaId)
    .where("nombre", "==", "orden")
    .where("año", "==", año)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        docRef = doc;
      });
      return docRef;
    })
    .then(async (docRef) => {
      const ordenSeqRef = db.collection("secuencias").doc(docRef.id);
      await db.runTransaction(async (trx) => {
        const docRef = await trx.get(ordenSeqRef);
        let newValor = docRef.data().valor + 1;
        trx.update(ordenSeqRef, { valor: newValor });
        valor = newValor;
      });
    });
  return `${empresaId.toString().padStart(3, "0")}-${año}-${valor
    .toString()
    .padStart(3, "0")}`;
};

export const actualizaOrden = (orden) => {
  return function (dispatch) {
    let ordenRef = db.collection("ordenes").doc(orden.id);
    dispatch(httpRequest());
    ordenRef
      .withConverter(ordenConverter)
      .set(orden)
      .then(() => {
        dispatch(httpSuccess());
        dispatch(setOrden(orden));
        dispatch(updateOrdenInArray(orden));
      })
      .catch((err) => {
        dispatch(httpFailure(err));
      });
  };
};

export const nuevaOrden = (orden) => {
  return function (dispatch) {
    var batch = db.batch();
    let ordenRef = db.collection("ordenes").doc();
    dispatch(httpRequest());
    const yyyy = new Date().getFullYear();

    const ejecuta = async () => {
      orden.numCor = await nextOrden(orden.empresaId, yyyy);
      orden.id = ordenRef.id;
      batch.set(ordenRef, orden);

      await batch
        .commit()
        .then(() => {
          dispatch(addOrdenInArray(orden));
          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    };

    ejecuta();
  };
};

export const sendOrden = (orden) => {
  return function (dispatch) {
    var batch = db.batch();
    let ordenRef = db.collection("ordenes").doc(orden.id);
    dispatch(httpRequest());

    const ejecuta = async () => {
      batch.set(ordenRef, orden);
      orden.items.forEach((prestacion) => {
        let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
        batch.update(prestacionRef, {
          lab: {
            ...prestacion,
            ordenId: orden.id,
            nombre: orden.labNombre,
            estado: orden.estado,
            numCor: orden.numCor,
          },
        });
      });

      await batch
        .commit()
        .then(() => {
          dispatch(updateOrdenInArray(orden));
          orden.items.forEach((pr) => {
            dispatch(
              updateLabInPrestationInArray({
                prestacionId: pr.id,
                nombre: orden.labNombre,
                estado: orden.estado,
                numCor: orden.numCor,
              })
            );
          });

          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    };

    ejecuta();
  };
};

export const cambiaEstadoOrden = (orden, estado) => {
  return function (dispatch) {
    var batch = db.batch();
    let ordenRef = db.collection("ordenes").doc(orden.id);
    dispatch(httpRequest());

    const ejecuta = async () => {
      orden.estado = estado;
      batch.update(ordenRef, { estado: estado });
      orden.items.forEach((prestacion) => {
        let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
        batch.update(prestacionRef, {
          lab: {
            ...prestacion,
            ordenId: orden.id,
            nombre: orden.labNombre,
            estado: estado,
            numCor: orden.numCor,
          },
        });
      });

      await batch
        .commit()
        .then(() => {
          dispatch(updateOrdenInArray(orden));
          orden.items.forEach((pr) => {
            dispatch(
              updateLabInPrestationInArray({
                prestacionId: pr.id,
                nombre: orden.labNombre,
                estado: estado,
                numCor: orden.numCor,
              })
            );
          });

          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    };

    ejecuta();
  };
};

export const anulaOrden = (orden) => {
  const ANULADO = "ANULADO";
  return function (dispatch) {
    var batch = db.batch();
    let ordenRef = db.collection("ordenes").doc(orden.id);
    dispatch(httpRequest());

    const ejecuta = async () => {
      orden.estado = ANULADO;
      batch.update(ordenRef, { estado: ANULADO });
      orden.items.forEach((prestacion) => {
        let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
        batch.update(prestacionRef, {
          lab: {
            ...prestacion.lab,
            nombre: "",
            estado: "PENDIENTE",
            numCor: "",
          },
        });
      });

      await batch
        .commit()
        .then(() => {
          dispatch(updateOrdenInArray(orden));
          orden.items.forEach((pr) => {
            dispatch(
              updateLabInPrestationInArray({
                prestacionId: pr.id,
                nombre: "",
                estado: "PENDIENTE",
                numCor: "",
              })
            );
          });

          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    };

    ejecuta();
  };
};

export const receiveOrden = (orden) => {
  const PRUEBAS = "PRUEBAS";
  return function (dispatch) {
    var batch = db.batch();
    let ordenRef = db.collection("ordenes").doc(orden.id);
    dispatch(httpRequest());

    const ejecuta = async () => {
      orden.estado = PRUEBAS;
      batch.update(ordenRef, { estado: PRUEBAS });
      orden.items.forEach((prestacion) => {
        let prestacionRef = db.collection("prestaciones").doc(prestacion.id);
        batch.update(prestacionRef, {
          lab: {
            ...prestacion.lab,
            codLab: prestacion.codLab,
            estado: PRUEBAS,
          },
        });
      });

      await batch
        .commit()
        .then(() => {
          dispatch(updateOrdenInArray(orden));
          orden.items.forEach((pr) => {
            dispatch(
              updateLabInPrestationInArray({
                prestacionId: pr.id,
                estado: PRUEBAS,
              })
            );
          });

          dispatch(httpSuccess());
        })
        .catch((err) => {
          dispatch(httpFailure(err));
        });
    };

    ejecuta();
  };
};

export const receiveOrden_old = (orden) => {
  return function (dispatch) {
    dispatch(httpRequest());
    recibirOrdenDb(orden)
      .then(() => {
        dispatch(updateOrdenInArray(orden));
        dispatch(httpSuccess());
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
};
